







































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import md5 from 'md5'
import {
  Collection,
  View,
  ComponentType,
  Table,
  PaginatedQueryResult
} from '@/models'
import ComponentCreateDialog from '@/components/ComponentCreateDialog.vue'
import * as ComponentTypes from '@/components/componentTypes'
import gql from 'graphql-tag'
import FuzzySearch from 'fuzzy-search'
import Loading from '@/components/Loading.vue'

@Component({
  components: {
    Loading,
    ComponentCreateDialog
  },
  apollo: {
    result: {
      query() {
        return gql`
          query getLists($environmentId: ID) {
            result: ${this.componentTypeName}(environmentId: $environmentId) {
              items ${this.componentType.queryFields}
            }
          }
        `
      },
      variables() {
        return {
          environmentId: this.environmentId
        }
      },
      fetchPolicy: 'network-only'
    }
  }
})
export default class AdminComponentsList extends Vue {
  /** Current Environment ID */
  @Prop({ type: String, required: true }) environmentId!: string
  /** Current component type name */
  @Prop({ type: String, required: true }) componentTypeName!: string

  /** Current search query */
  searchQuery = ''
  /** Current sorting mode */
  sortBy = 'name'
  /** Create modal state */

  createModalOpen = false
  drawer: boolean = true

  get showDrawer() {
    if (this.$vuetify.breakpoint.lgAndUp) {
      this.drawer = true
      return true
    } else {
      this.drawer = false
      return false
    }
  }

  /** Current component type */
  get componentType(): ComponentType<any> {
    let ret = (ComponentTypes as Record<string, ComponentType<any>>)[
      this.componentTypeName
    ]
    return ret
  }

  /** Open or close the creation modal */
  toggleCreateModal(value: any) {
    this.createModalOpen =
      value != null && typeof value === 'boolean'
        ? value
        : !this.createModalOpen
  }

  async handleCreate(component: any) {
    await this.$apollo.queries.result.refetch()
    if (component && component._id) {
      return this.navigateToComponent(component._id)
    }
  }

  navigateToComponent(componentId: string) {
    return this.$router.push({
      name: 'adminComponentEdit',
      params: {
        componentId
      }
    })
  }

  // Comenzando a crear las funciones para traer las listas
  result?: PaginatedQueryResult<any>

  get filteredLists() {
    if (!this.result) return []
    let displayResult = this.result.items.map(
      this.componentType.transformResult || ((e) => e)
    )
    const searcher = new FuzzySearch(displayResult || [], ['name', '_id', 'title'])
    return searcher.search(this.searchQuery)
  }
}
